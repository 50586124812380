import React from "react";
import ReactDOM from "react-dom";
import { Login, AppTranslation, Theme } from "@nigrivhub/nigriv-ui";
import api from "./apps/api";
import moment from 'moment-timezone';
import 'moment/locale/pl';
moment.locale('pl');

ReactDOM.render(
    <AppTranslation>
        <Theme settings={{ formsPrimary: "blue" }}>
            <Login
                logoTopHref="#/"
                logoTop="https://cdn.nigrivhub.com/website/logos/nigriv_logo_white.svg"
                logoCenter="https://cdn.nigrivhub.com/website/logos/assessment_logo.png"
                azureSSO={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${window.location.hostname.endsWith(".local") ? "544616f4-ebf6-47fb-8460-8b633decc1b8" : "e6efe3b2-a774-4bff-9b98-1e6a044985bd"}&scope=offline_access%20https%3A%2F%2Fgraph.microsoft.com%2FUser.Read%20https%3A%2F%2Fgraph.microsoft.com%2Fprofile&response_type=code&redirect_uri=https://api.${window.location.hostname}/auth/azure`}
                onFormSubmit={async ({ values }) => {
                    let resp = await api.Login(values());
                    if (!resp || !resp.jwt) return;
                    window.location = "/consume-jwt?token=" + resp.jwt;
                }}
                allowNotEmailAsUsername

                className="min-h-full flex flex-col sm:bg-slate-800"
                navbarClassNames="h-20 flex justify-start items-center px-4 bg-blue-600"
                centralClassNames="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 sm:w-[400px]"
                footbarClassNames="sm:h-20 bg-slate-900 flex flex-col sm:flex-row justify-center items-center px-4"

                privacyPolicy="https://cdn.nigrivhub.com/website/public-docs/POLITYKA%20PRYWATNO%C5%9ACI%20I%20CIASTEK.pdf"
                termsOfUse="https://cdn.nigrivhub.com/website/public-docs/REGULAMIN%20NIGRIV.pdf"
                productPublishYear={2022}
            />
        </Theme>
    </AppTranslation>,
    document.getElementById("root")
);